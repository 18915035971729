<script>
import displaySettings from "@/mixin/displaySettings";

export default {
    name: "HistoryTabs",
    mixins:[displaySettings],
    data() {
        return {
            listQuery: {
                statusId: 0,
            },
        }
    },
    computed:{
        orderStatuses: function () {
            return this.$store.getters.ORDER_STATUSES;
        },
    },
    methods: {
        activateTab :function (status) {
            this.$set(this.listQuery, 'statusId', status.id);
            this.$emit('change-tab', status)
        },
    }
}
</script>

<template>
<div class="list-orders container p-0">
    <div v-if="!isMobile" class="tabs-block">
        <ul class="status-tabs">
            <li @click="activateTab(status)" v-for="(status, index) in orderStatuses" class="tab">
                <a v-bind:class="{ active: listQuery.statusId === status.id }"
                   href="javascript:void(0);"> {{ status.name || 'Всі' }}</a>
            </li>
        </ul>
    </div>
    <div v-else>
        <div class="form-group">
            <el-select v-model="listQuery.statusId"
                       class="form-control-el"
                       placeholder="Виберіть статус"
                       @change="activateTab({id: $event})"
            >
                <el-option v-for="item in orderStatuses"
                           :key="item.id"
                           :label="item.name || 'Всі'"
                           :value="item.id"
                           >
                </el-option>
            </el-select>
        </div>
    </div>

    <slot name="content"></slot>
</div>
</template>

<style scoped>

</style>
