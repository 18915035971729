<script>
import {HistoryMixin, orderMixin} from "@/mixin";
export default {
    name: "EmptyState",
    mixins: [HistoryMixin, orderMixin],
    data() {
        return {
            listQuery: {
                statusId: 0,
            },
        }
    },
    methods: {
        isDraftTab: function () {
            return this.listQuery.statusId === this.draftStatus;
        },
        isDeclaredTab: function () {
            return this.listQuery.statusId === this.declaredStatus;
        },
        isCanceledTab: function () {
            return this.listQuery.statusId === this.canceledStatus;
        },
        filterStatusWrap: function() {
            return this.$emit('filter-status-wrap');
        },
    }
}
</script>

<template>
    <div class="container empty-notification">
        <img src="~@/assets/images/empty_img.svg" alt="">
        <div class="empty-notification__text">
            <p v-if="isProlongation()">Тут будуть з`являтися страховi полiси, дата закiнчення яких через 30 днiв, або ранiше.
                Оформіть страховку або змініть параметри  <a href="javascript:void(0);" @click="filterStatusWrap()">фільтра</a>  замовлень.</p>
            <p v-else-if="isDraftTab()">Тут будуть з'являтися страхові поліси зі статусом чернетка. Створіть чернетку або змініть параметри <a href="javascript:void(0);" @click="filterStatusWrap()">фільтра</a> замовлень.</p>
            <p v-else-if="isDeclaredTab()">Тут будуть з'являтися страхові поліси зі статусом заявлений. Заявіть чернетку або змініть параметри <a href="javascript:void(0);" @click="filterStatusWrap()">фільтра</a> замовлень.</p>
            <p v-else-if="isCanceledTab()">Тут будуть з'являтися страхові поліси зі статусом припинений. Змінити параметри <a href="javascript:void(0);" @click="filterStatusWrap()">фільтра</a> замовлень.</p>
            <p v-else>Ваша історія замовлень поки порожня. Оформіть страховку або змініть параметри
                <a href="javascript:void(0);" @click="filterStatusWrap()">фільтра</a> замовлень.</p>
        </div>
        <a data-toggle="modal" data-target="#modalSearch" class="header__create">Cтворити
            страховку</a>
    </div>
</template>

<style scoped>

</style>
