<template>
    <div class="p-0">
<!--        //TODO refactoring not end-->
        <history-tabs @change-tab="activateTab">
            <template v-slot:content>
                <empty-state
                    v-if="! isLoadData && (ordersList && ordersList.length < 1)"
                    @filter-status-wrap="filterStatusWrap"
                ></empty-state>
                <div class="container-wrapp" v-else-if="! isMobile">
                    <div class="header-block">
                        <ul class="columns-block">
                            <li class="column">Тип статусу</li>
                            <li class="column">Номер поліса</li>
                            <li class="column">Дата</li>
                            <li class="column">Страхувальник</li>
                            <li class="column">Вартість, грн</li>
                            <li class="column">Заробіток, грн</li>
                        </ul>
                    </div>
                    <div v-if="isLoadData">
                        <row-block-skeleton v-for="index in 4" :key="index"></row-block-skeleton>
                    </div>
                    <div v-else v-for="order in ordersList" class="row-block">
                        <ul class="columns-block">
                            <li class="column">
                                <div class="column-block">
                                    <span class="order-type">{{ showTypeInsurance(order) }}</span>
                                    <span v-if="isProlongationOrder(order)" style="margin-top: 10px">
                                  <svg style="margin-top: -7px;" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <line x1="10" y1="4" x2="10" y2="12" stroke="#EF4E4E" stroke-width="2"/>
                                    <line x1="10" y1="13.5" x2="10" y2="16" stroke="#EF4E4E" stroke-width="2"/>
                                    <circle cx="10" cy="10" r="9" stroke="#EF4E4E" stroke-width="2"/>
                                  </svg>
                                    <span>
                                        Cкоро закінчується
                                    </span>
                                </span>
                                <div class="bottom-part order-status">
                                    <div class="bottom-block right-border">
                                        <span>Статус:</span>
                                        <div>
                                            <div :class="['status', getStatusClass(order)]">
                                            <span>{{ showStatusName(order) }}</span>
                                            </div>
                                            <div v-if="checkOrderPaid(order) && (showStatusId(order) === 5 || order.typeId === 5)" class="payment-status paid mt-1"><span>Сплачено</span></div>
                                            <div v-if="!checkOrderPaid(order) && (showStatusId(order) === 5 || showStatusId(order) === 10)" class="payment-status mt-1"><span>Не сплачено</span></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="column">
                            <div class="column-block">
                                <a  class="order-number" target="_blank"  :href="showMtsbuLink(order)" v-if="showMtsbuLink(order)">
                                    {{ showMtsbuCode(order) }}<br />{{ showMtsbuCodeAdditionalInfo(order) }}

                                    </a>
                                    <span v-else-if="showMtsbuCode(order)" class="order-number">
                                            {{ showMtsbuCode(order) }}
                                        </span>
                                    <span class="order-number" v-else>&mdash;</span>
                                    <div class="bottom-part order-user">
                                        <div class="bottom-block right-border">
                                            <span>Точка продажу:</span>
                                            <div class="user-name"
                                                 data-toggle="tooltip" data-placement="bottom"
                                                 :title="showNameInsured(order)">
                                                {{ showNameInsured(order) }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="column">
                                <div class="column-block">
                                    <div>
                                        <div class="date-block">
                                            <div class="text">Створений:</div>
                                            <span v-if="order.createdAt" class="date">{{ order.createdAt }}</span>
                                            <span v-else class="date">&mdash;</span>
                                        </div>
                                        <div class="date-block">
                                            <div class="text">Початок:</div>
                                            <span v-if="showStartedAtContract(order)"
                                                  class="date">{{ showStartedAtContract(order) }}</span>
                                            <span v-else class="date">&mdash;</span>
                                        </div>
                                        <div class="date-block">
                                            <div class="text">Закінчення:</div>
                                            <span v-if="showFinishedAtContract(order)"
                                                  class="date">{{ showFinishedAtContract(order) }}</span>
                                            <span v-else class="date">&mdash;</span>

                                        </div>
                                    </div>
                                    <div class="bottom-part order-company">
                                        <div class="bottom-block right-border">
                                            <span class="option">СК:</span>
                                            <div class="result"
                                                 data-toggle="tooltip" data-placement="bottom"
                                                 :title="order.companyInfo.name">
                                                <ellipsis
                                                    :data="order.companyInfo.name">
                                                </ellipsis>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="column">
                                <div class="column-block">
                                    <div class="order-insured">
                                        <a class="dark-link" v-if="order.client_id" :href="'/clients/'+order.client_id">
                                            <div v-if="showNameInsurantSurname(order)">
                                                <span>{{ showNameInsurantSurname(order) }}</span>
                                            </div>
                                            <div style="">
                                                <span>{{ showNameInsurantName(order) }}</span>
                                            </div>
                                            <div style="">
                                                <span>{{ showNameInsurantPatronymic(order) }}</span>
                                            </div>
                                        </a>
                                        <div v-else>
                                            <div v-if="showNameInsurantSurname(order)">
                                                <span>{{ showNameInsurantSurname(order) }}</span>
                                            </div>
                                            <div style="">
                                                <span>{{ showNameInsurantName(order) }}</span>
                                            </div>
                                            <div style="">
                                                <span>{{ showNameInsurantPatronymic(order) }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="bottom-part order-company">
                                        <div class="bottom-block right-border">
                                            <span class="result">{{ order.insuranceObject.number }}</span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="column">
                                <div class="column-block">
                                    <div>
                                        <div class="price-block">
                                            <div class="text">Первісна:</div>
                                            <span class="date">{{ showPrice(order) }}</span>
                                        </div>
                                        <div v-if="showDiscount(order)"  class="price-block">
                                            <div class="text">Зі знижкою:</div>
                                            <span class="date">{{ showAllPrice(order) }} <i class="icon question" data-html="true" data-placement="bottom" data-select="true" data-toggle="tooltip" title="Первісна вартість може бути знижена за рахунок знижки посередника та за рахунок виключення місяців, з періоду дії полісу."></i></span>
                                        </div>
                                    </div>
                                    <div class="bottom-part">
                                        <div class="bottom-part">
                                            <div v-if="isProlongation(order)">
                                                <svg style="margin-bottom: 4px" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M11.6 3.2002H4.4C3.07452 3.2002 2 4.27471 2 5.6002V11.6002C2 12.9257 3.07452 14.0002 4.4 14.0002H11.6C12.9255 14.0002 14 12.9257 14 11.6002V5.6002C14 4.27471 12.9255 3.2002 11.6 3.2002Z" stroke="#999DA6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                    <path d="M5.59961 2V4.4" stroke="#999DA6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                    <path d="M10.4004 2V4.4" stroke="#999DA6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                    <path d="M2 6.80029H14" stroke="#999DA6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                </svg>

                                                <a @click="prolong(order)" href="javascript:void(0);">
                                                    <span  style="margin-left: 1px;" class="option">Продовжити</span>
                                                </a>

                                                <div class="d-inline-flex">
                                                    <client-info :client="order.insurant"  ref="clientInfo"></client-info>
                                                </div>
                                            </div>
                                            <div v-else-if="isDraft(order)">
                                                <svg style="margin-bottom: 4px" width="16" height="16" viewBox="0 0 16 16"
                                                     fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M12.0415 7.74999C12.0398 8.88764 11.5871 9.9782 10.7827 10.7826C9.97822 11.5871 8.88766 12.0398 7.75001 12.0415C6.61223 12.0399 5.52149 11.5873 4.71686 10.7828C3.91223 9.97841 3.45936 8.88777 3.45751 7.74999C3.45896 6.61199 3.91167 5.52103 4.71636 4.71634C5.52104 3.91166 6.61201 3.45894 7.75001 3.45749C8.70651 3.45749 9.58251 3.77249 10.295 4.30049L9.40401 5.19249L13.619 6.32049L12.489 2.10699L11.544 3.05199C10.4712 2.18163 9.13151 1.70689 7.75001 1.70749C4.41301 1.70899 1.70901 4.41249 1.70801 7.74999C1.70901 11.0875 4.41301 13.7915 7.75001 13.7915C11.087 13.7915 13.7915 11.0875 13.7915 7.74999H12.0415Z"
                                                        fill="#999DA6"/>
                                                </svg>
                                                <a @click="declare(order,false,false,1)" href="javascript:void(0);">
                                                <span class="option">
                                                Продовжити
                                            </span>
                                                </a>
                                            </div>
                                            <div v-else-if="isDeclared(order)">
                                                <svg style="margin-bottom: 5px" width="16" height="16"
                                                     viewBox="0 0 16 16" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <mask id="path-1-inside-1" fill="white">
                                                        <rect x="1" y="5" width="14" height="10" rx="1"/>
                                                    </mask>
                                                    <rect x="1" y="5" width="14" height="10" rx="1"
                                                          stroke="#24282C" stroke-width="4"
                                                          mask="url(#path-1-inside-1)"/>
                                                    <mask id="path-2-inside-2" fill="white">
                                                        <rect x="4" y="1" width="8" height="6" rx="1"/>
                                                    </mask>
                                                    <rect x="4" y="1" width="8" height="6" rx="1"
                                                          stroke="#24282C" stroke-width="4"
                                                          mask="url(#path-2-inside-2)"/>
                                                </svg>
                                                <a @click="declare(order,false,false,2)" href="javascript:void(0);">
                                                <span class="option">
                                                Продовжити
                                            </span>
                                                </a>
                                            </div>
                                            <div v-else-if="isConcluded(order) && order.typeId !== 2">
                                            <span class="option image">
                                              <svg style="margin-bottom: 5px" width="16" height="16"
                                                   viewBox="0 0 16 16" fill="none"
                                                   xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#clip0)">
                                                <path
                                                    d="M0.666504 8C0.666504 8 3.33317 2.66667 7.99984 2.66667C12.6665 2.66667 15.3332 8 15.3332 8C15.3332 8 12.6665 13.3333 7.99984 13.3333C3.33317 13.3333 0.666504 8 0.666504 8Z"
                                                    stroke="#24282C" stroke-width="2" stroke-linecap="round"
                                                    stroke-linejoin="round"/>
                                                <path
                                                    d="M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z"
                                                    stroke="#24282C" stroke-width="2" stroke-linecap="round"
                                                    stroke-linejoin="round"/>
                                                    </g>
                                                    <defs>
                                                    <clipPath id="clip0">
                                                    <rect width="16" height="16" fill="white"/>
                                                    </clipPath>
                                                    </defs>
                                                </svg>
                                            </span>
                                                <a @click="goToOrderInfo(order)" href="javascript:void(0);">
                                                <span class="option">
                                                    Переглянути
                                                </span>
                                                </a>
                                            </div>
                                            <div v-else-if="isOverdue(order)">
                                            <span class="option image">
                                                 <div v-if="loadMoreOptions[order.id + order.typeId] === true"
                                                      class="option-loader spinner-border"
                                                      style="height: 14px; width: 14px; margin-bottom: 7px; margin-right: 2px"
                                                      role="status">
                                                </div>
                                                 <svg v-else style="margin-bottom: 5px;" width="16" height="16" viewBox="0 0 16 16" fill="none"
                                                      xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M13.3333 6H7.33333C6.59695 6 6 6.59695 6 7.33333V13.3333C6 14.0697 6.59695 14.6667 7.33333 14.6667H13.3333C14.0697 14.6667 14.6667 14.0697 14.6667 13.3333V7.33333C14.6667 6.59695 14.0697 6 13.3333 6Z"
                                                        stroke="#24282C" stroke-width="2" stroke-linecap="round"
                                                        stroke-linejoin="round"/>
                                                    <path
                                                        d="M3.3335 10H2.66683C2.31321 10 1.97407 9.85952 1.72402 9.60947C1.47397 9.35942 1.3335 9.02028 1.3335 8.66666V2.66666C1.3335 2.31304 1.47397 1.9739 1.72402 1.72385C1.97407 1.4738 2.31321 1.33333 2.66683 1.33333H8.66683C9.02045 1.33333 9.35959 1.4738 9.60964 1.72385C9.85969 1.9739 10.0002 2.31304 10.0002 2.66666V3.33333"
                                                        stroke="#24282C" stroke-width="2" stroke-linecap="round"
                                                        stroke-linejoin="round"/>
                                                </svg>
                                            </span>
                                                <a @click="copy(order)" href="javascript:void(0);">
                                                <span class="option">
                                                    Копіювати
                                                </span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="column">
                                <div class="column-block">
                                    <div>
                                        <div class="price-block" v-if="order.allEarnings">
                                            <div class="text">Загальний:</div>
                                            <span class="price">{{ order.allEarnings }}</span>
                                        </div>
                                        <div class="price-block">
                                            <div class="text">Ваш:</div>
                                            <span v-if="order.myEarnings" class="price">{{ order.myEarnings }}</span>
                                            <span v-else class="price">&mdash;</span>
                                        </div>
                                        <div class="price-block" v-if="order.isPartnerOrder">
                                            <div class="text">Агента:</div>
                                            <span v-if="order.partnerEarnings" class="price">{{
                                                    order.partnerEarnings
                                                }}</span>
                                            <span v-else class="date">&mdash;</span>
                                        </div>
                                    </div>
                                    <div class="bottom-part">
                                        <div v-if="loadMoreOptions[order.id + order.typeId] === true"
                                             class="option-loader spinner-border"
                                             style="height: 14px; width: 14px; margin-bottom: 4px;"
                                             role="status">
                                        </div>
                                        <more-options v-else :status-order="showStatusId(order)"
                                                      :order="order"
                                                      :order-type="order.typeId"
                                                      :data-contract-extra-files="order.contract.extraFiles"
                                                      @download-draft="showDraft(order)"
                                                      @download-contract="takeContract(order, 1)"
                                                      @show-order="goToOrderInfo(order)"
                                                      @proceed-order="declare(order,false,false,1)"
                                                      @confirm-order="declare(order,false,false,2)"
                                                      @open-mtsbu-link="openMtsbuLink(order)"
                                                      @get-all-payment-ways="showAllOrderPaymentWays(order)"
                                                      @get-payment-invoice="getPaymentInvoice(order)"
                                                      @get-instant-loan="getInstantLoan(order)"
                                                      :is-exist-mtsbu-link="showMtsbuLink(order)"
                                                      @copy-order="copy(order)">
                                        </more-options>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div v-else>
                    <div class="mobile-columns-block">
                        <div v-if="isLoadData">
                            <row-mobile-block-skeleton v-for="index in 4" :key="index"></row-mobile-block-skeleton>
                        </div>
                        <div v-else>
                            <div v-for="order in ordersList" class="row order-data">
                                <div v-if="isProlongationOrder(order)" class="col-12 mb-3">
                                    <div style="margin-top: 10px">
                                        <span>
                                          <svg style="margin-top: -7px;" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <line x1="10" y1="4" x2="10" y2="12" stroke="#EF4E4E" stroke-width="2"/>
                                            <line x1="10" y1="13.5" x2="10" y2="16" stroke="#EF4E4E" stroke-width="2"/>
                                            <circle cx="10" cy="10" r="9" stroke="#EF4E4E" stroke-width="2"/>
                                          </svg>
                                        </span>
                                        <span>
                                       Скоро закінчується
                                    </span>
                                    </div>
                                </div>
                                <div class="col-7 half-block">
                                    <div class="info-block">
                                        <div class="option">Тип поліса</div>
                                        <div class="value">{{ showTypeInsurance(order) }}</div>
                                    </div>
                                    <div class="info-block">
                                        <div class="option">Дата</div>
                                        <div class="sub-options">
                                            <div>
                                                <span class="sub-option">Створений:</span>
                                                <span v-if="order.createdAt" class="value">{{ order.createdAt }}</span>
                                                <span v-else class="value">&mdash;</span>
                                            </div>
                                            <div>
                                                <span class="sub-option">Початок:</span>
                                                <span v-if="showStartedAtContract(order)"
                                                      class="value">{{ showStartedAtContract(order) }}</span>
                                                <span v-else class="value">&mdash;</span>
                                            </div>
                                            <div>
                                                <span class="sub-option">Закінчення:</span>
                                                <span v-if="showFinishedAtContract(order)"
                                                      class="value">{{ showFinishedAtContract(order) }}</span>
                                                <span v-else class="value">&mdash;</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="info-block">
                                        <div class="option">Вартість, грн</div>
                                        <div class="sub-options">
                                            <div>
                                                <span class="sub-option">Первісна:</span>
                                                <span class="value">{{ showPrice(order) }}</span>
                                            </div>
                                            <div v-if="showDiscount(order)">
                                                <span class="sub-option">Зі знижкою:</span>
                                                <span class="value">{{ showDiscount(order) }}</span>
                                            </div>
                                            <div v-if="showDgoPrice(order)">
                                                <span class="sub-option">ДЦВ:</span>
                                                <span class="value">{{ showDgoPrice(order) }}</span>
                                            </div>

                                        <div class="mt-4 mb-1">
                                            <span class="sub-option">Статус:</span>
                                            <div :class="['value status', getStatusClass(order)]">
                                                <span>{{ showStatusName(order) }}</span>
                                            </div>
                                                <div v-if="checkOrderPaid(order) && (showStatusId(order) === 5 || order.typeId === 5)" class="payment-status paid mt-1 ml-5"><span>Сплачено</span></div>
                                                <div v-if="!checkOrderPaid(order) && (showStatusId(order) === 5 || showStatusId(order) === 10)" class="payment-status mt-1 ml-5"><span>Не сплачено</span></div>
                                        </div>
                                        <div class="d-flex">
                                            <span class="sub-option">Точка продажу:</span>
                                            <span data-toggle="tooltip" data-placement="bottom"
                                                  :title="showNameInsured(order)"
                                                  class="value">
                                            {{ showNameInsured(order) }}
                                        </span>
                                            </div>
                                            <div class="d-flex">
                                                <span class="sub-option">СК:</span>
                                                <span class="value">
                                            <ellipsis :data="order.companyInfo.name"></ellipsis>
                                        </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-5 half-block">
                                    <div class="info-block">
                                        <div class="option">Номер поліса</div>
                                        <div class="value">
                                            <a :href="showMtsbuLink(order)" v-if="showMtsbuLink(order)">
                                                {{ showMtsbuCode(order) }}
                                            </a>
                                            <span v-else-if="showMtsbuCode(order)" class="order-number">
                                            {{ showMtsbuCode(order) }}
                                        </span>
                                            <span class="order-number" v-else>&mdash;</span>
                                        </div>
                                    </div>
                                    <div class="info-block">
                                        <div class="option">Страхувальник</div>
                                        <a class="dark-link"  :href="'/clients/'+order.client_id">
                                            <div class="value">
                                                {{ showNameInsurantSurname(order) }}
                                            </div>
                                            <div class="value">
                                                {{ showNameInsurantName(order) }}
                                            </div>
                                            <div class="value">
                                                {{ showNameInsurantPatronymic(order) }}
                                            </div>
                                        </a>
                                    </div>
                                    <div class="info-block">
                                        <div class="option">Заробіток, грн</div>
                                        <div class="sub-options">
                                            <div v-if="order.isPartnerOrder">
                                                <span class="sub-option">Загальний:</span>
                                                <span v-if="order.allEarnings" class="value">{{ order.allEarnings }}</span>
                                                <span v-else class="value">&mdash;</span>
                                            </div>
                                            <div>
                                                <span class="sub-option">Ваш:</span>
                                                <span v-if="order.myEarnings" class="value">{{ order.myEarnings }}</span>
                                                <span v-else class="value">&mdash;</span>
                                            </div>
                                            <div v-if="order.isPartnerOrder">
                                                <span class="sub-option">Агента:</span>
                                                <span v-if="order.partnerEarnings" class="value">{{
                                                        order.partnerEarnings
                                                    }}</span>
                                                <span v-else class="value">&mdash;</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div style="margin-top: auto;color: #999DA6;" class="info-block">
                                        <div class="info-link">
                                            <div class="mb-2" style="color: #24282C; font-size: 14px">{{ order.insuranceObject.number }}</div>
                                        </div>
                                        <div class="info-link" v-if="isProlongation(order)">
                                            <svg style="margin-bottom: 4px" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M11.6 3.2002H4.4C3.07452 3.2002 2 4.27471 2 5.6002V11.6002C2 12.9257 3.07452 14.0002 4.4 14.0002H11.6C12.9255 14.0002 14 12.9257 14 11.6002V5.6002C14 4.27471 12.9255 3.2002 11.6 3.2002Z" stroke="#999DA6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M5.59961 2V4.4" stroke="#999DA6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M10.4004 2V4.4" stroke="#999DA6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M2 6.80029H14" stroke="#999DA6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                            <span @click="prolong(order)">Продовжити</span>

                                            <div class="d-inline-flex" style="margin-left: -10px;">
                                                <client-info :client="order.insurant"  ref="clientInfo"></client-info>
                                            </div>
                                        </div>
                                        <div class="info-link" v-else-if="isConcluded(order)">
                                            <svg width="16" height="16"
                                                 viewBox="0 0 16 16" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#clip0)">
                                                    <path
                                                        d="M0.666504 8C0.666504 8 3.33317 2.66667 7.99984 2.66667C12.6665 2.66667 15.3332 8 15.3332 8C15.3332 8 12.6665 13.3333 7.99984 13.3333C3.33317 13.3333 0.666504 8 0.666504 8Z"
                                                        stroke="#999DA6" stroke-width="2" stroke-linecap="round"
                                                        stroke-linejoin="round"/>
                                                    <path
                                                        d="M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z"
                                                        stroke="#999DA6" stroke-width="2" stroke-linecap="round"
                                                        stroke-linejoin="round"/>
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0">
                                                        <rect width="16" height="16" fill="white"/>
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                            <span @click="goToOrderInfo(order)">Переглянути</span>
                                        </div>
                                        <div class="info-link" v-else-if="isOverdue(order)">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M13.3333 6H7.33333C6.59695 6 6 6.59695 6 7.33333V13.3333C6 14.0697 6.59695 14.6667 7.33333 14.6667H13.3333C14.0697 14.6667 14.6667 14.0697 14.6667 13.3333V7.33333C14.6667 6.59695 14.0697 6 13.3333 6Z"
                                                    stroke="#999DA6" stroke-width="2" stroke-linecap="round"
                                                    stroke-linejoin="round"/>
                                                <path
                                                    d="M3.3335 10H2.66683C2.31321 10 1.97407 9.85952 1.72402 9.60947C1.47397 9.35942 1.3335 9.02028 1.3335 8.66666V2.66666C1.3335 2.31304 1.47397 1.9739 1.72402 1.72385C1.97407 1.4738 2.31321 1.33333 2.66683 1.33333H8.66683C9.02045 1.33333 9.35959 1.4738 9.60964 1.72385C9.85969 1.9739 10.0002 2.31304 10.0002 2.66666V3.33333"
                                                    stroke="#999DA6" stroke-width="2" stroke-linecap="round"
                                                    stroke-linejoin="round"/>
                                            </svg>
                                            <span @click="copy(order)">Копіювати</span>
                                        </div>
                                        <div class="info-link" v-else-if="isDraft(order)">
                                            <svg width="16" height="16" viewBox="0 0 16 16"
                                                 fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M12.0415 7.74999C12.0398 8.88764 11.5871 9.9782 10.7827 10.7826C9.97822 11.5871 8.88766 12.0398 7.75001 12.0415C6.61223 12.0399 5.52149 11.5873 4.71686 10.7828C3.91223 9.97841 3.45936 8.88777 3.45751 7.74999C3.45896 6.61199 3.91167 5.52103 4.71636 4.71634C5.52104 3.91166 6.61201 3.45894 7.75001 3.45749C8.70651 3.45749 9.58251 3.77249 10.295 4.30049L9.40401 5.19249L13.619 6.32049L12.489 2.10699L11.544 3.05199C10.4712 2.18163 9.13151 1.70689 7.75001 1.70749C4.41301 1.70899 1.70901 4.41249 1.70801 7.74999C1.70901 11.0875 4.41301 13.7915 7.75001 13.7915C11.087 13.7915 13.7915 11.0875 13.7915 7.74999H12.0415Z"
                                                    fill="#999DA6"/>
                                            </svg>
                                            <span @click="declare(order)">Продовжити</span>
                                        </div>
                                        <div class="info-link" v-else-if="isDeclared(order)">
                                            <svg width="16" height="16" viewBox="0 0 16 16"
                                                 fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M12.0415 7.74999C12.0398 8.88764 11.5871 9.9782 10.7827 10.7826C9.97822 11.5871 8.88766 12.0398 7.75001 12.0415C6.61223 12.0399 5.52149 11.5873 4.71686 10.7828C3.91223 9.97841 3.45936 8.88777 3.45751 7.74999C3.45896 6.61199 3.91167 5.52103 4.71636 4.71634C5.52104 3.91166 6.61201 3.45894 7.75001 3.45749C8.70651 3.45749 9.58251 3.77249 10.295 4.30049L9.40401 5.19249L13.619 6.32049L12.489 2.10699L11.544 3.05199C10.4712 2.18163 9.13151 1.70689 7.75001 1.70749C4.41301 1.70899 1.70901 4.41249 1.70801 7.74999C1.70901 11.0875 4.41301 13.7915 7.75001 13.7915C11.087 13.7915 13.7915 11.0875 13.7915 7.74999H12.0415Z"
                                                    fill="#999DA6"/>
                                            </svg>
                                            <span @click="declare(order,false,false,2)">Продовжити</span>
                                        </div>
                                        <div>
                                            <div v-if="loadMoreOptions[order.id + order.typeId] === true"
                                                 class="option-loader spinner-border"
                                                 style="height: 20px; width: 20px; margin-top: 11px;"
                                                 role="status">
                                            </div>
                                            <more-options style="padding-top: 5px"
                                                          v-else :status-order="showStatusId(order)"
                                                          @download-draft="downloadDraft(order)"
                                                          @download-contract="takeContract(order, 2)"
                                                          @show-order="goToOrderInfo(order)"
                                                          @proceed-order="declare(order)"
                                                          @confirm-order="declare(order,false,false,2)"
                                                          @open-mtsbu-link="openMtsbuLink(order)"
                                                          @get-all-payment-ways="showAllOrderPaymentWays(order)"
                                                          @get-payment-invoice="getPaymentInvoice(order)"
                                                          @get-instant-loan="getInstantLoan(order)"
                                                          :is-exist-mtsbu-link="showMtsbuLink(order)"
                                                          @copy-order="copy(order)"
                                                          :order="order"
                                                          :order-type="order.typeId"
                                                          :data-contract-extra-files="order.contract.extraFiles"
                                            >
                                            </more-options>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </history-tabs>
        <modal-get-invoice-order ref="modalGetInvoiceOrder"></modal-get-invoice-order>
        <modal-get-invoice-legal-order ref="modalGetInvoiceLegalOrder"></modal-get-invoice-legal-order>
        <modal-get-instant-loan ref="modalGetInstantLoan"></modal-get-instant-loan>
        <modal-get-mono-pay-parts ref="showModalGetMonoPayParts"></modal-get-mono-pay-parts>
        <modal-get-all-payment-ways
            ref="modalGetAllPaymentWays"
            @showModalGetInvoiceOrder="getPaymentInvoice"
            @showModalGetInstantLoan="getInstantLoan"
            @showModalGetMonoPayParts="showModalGetMonoPayParts"
            @showModalGetInvoiceLegal="showModalGetInvoiceLegal"
            :is-legal-type="false"
        >
        </modal-get-all-payment-ways>
<!--        <policy-cancel-dialog/>-->
    </div>
</template>


<script>
import Vue from "vue";
import {orderMixin, customerMixin, HistoryMixin} from '@/mixin'
import {MoreOptions, RowBlockSkeleton, RowMobileBlockSkeleton, ClientInfo} from './components'
import {
    GetPaymentLink as ModalGetInvoiceOrder,
    GetInstantLoan as ModalGetInstantLoan,
    GetMonoPayParts as ModalGetMonoPayParts,
    GetAllPaymentWays as ModalGetAllPaymentWays,
} from "@/components/app/modals/order";
import ModalGetInvoiceLegalOrder from '@/components/app/modals/order/GetInvoiceLegalOrder.vue'
import {downloadTourismContract} from "@/api/downloadTourismContract";
import {orderTourism} from "@/api/orderTourism";
import PolicyCancelDialog from "@/components/app/policyCancel/PolicyCancelDialog.vue";
import EmptyState from "@/views/history/components/EmptyState.vue";
import HistoryTabs from "@/views/history/components/HistoryTabs.vue";
import displaySettings from "@/mixin/displaySettings";

export default {
    mixins: [orderMixin, customerMixin, HistoryMixin, displaySettings],
    components: {
        HistoryTabs,
        EmptyState,
        PolicyCancelDialog,
        MoreOptions,
        RowBlockSkeleton,
        RowMobileBlockSkeleton,
        ClientInfo,
        ModalGetInvoiceOrder,
        ModalGetInvoiceLegalOrder,
        ModalGetInstantLoan,
        ModalGetMonoPayParts,
        ModalGetAllPaymentWays,
    },
    props: {
        countProlongation: {
            type: Number,
            default: () => {
                return 0;
            }
        },
        isLoadData: {
            type: Boolean,
            default: () => {
                return true
            }
        },
        prolongation: {
            type: Array,
            default: () => {
                return [{
                    dgo: {},
                    autolawyer: {},
                    statusId: null,
                    fullName: null,
                    earning: null,
                    contract: {},
                    companyInfo: {},
                    insurant: {},
                    insured: {},
                    price: null,
                    dgoPrice: null,
                    discountPrice: null,
                    cashbackPrice: null,
                }];
            },
        },
        orders: {
            type: Array,
            default: () => {
                return [{
                    dgo: {},
                    autolawyer: {},
                    statusId: null,
                    fullName: null,
                    earning: null,
                    contract: {},
                    contractDgo: {},
                    companyInfo: {},
                    insurant: {},
                    insured: {},
                    price: null,
                    dgoPrice: null,
                    discountPrice: null,
                    cashbackPrice: null,
                }];
            },
        },
    },
    data: function () {
        return {
            loadMoreOptions: {},
            ordersList: [],
            ordersResponceList: [],
            listQuery: {
                statusId: 0,
            },
            humans : [],
            birthDates: [],
        }
    },
    created() {
      this.ordersList = this.orders;
    },
    computed: {
        orderTypes: function () {
            return this.$store.getters.ORDER_TYPES
        },
    },
    methods: {
        filterStatusWrap: function() {
            return this.$emit('filter-status-wrap');
        },
        showTouristDraft: async function(order) {
            Vue.set(this.loadMoreOptions, order.id + order.typeId, true);

            let params = {
                type: 'save',
                statusId: null,
            };

            let offerInfo = {
                "id":order.offer.external_id,
                "company":order.companyInfo.info,
                "tripProgram":order.offer.trip_program,
                "title":order.companyInfo.name,
                "options":order.offer.options,
                "programs":order.offer.programs,
                "members":order.offer.members,
                "ages":order.offer.ages,
                "external_id":order.offer.external_id,
                "startDate":order.offer.startDate,
                "endDate":order.offer.endDate,
                "moduleId":order.moduleId,
                "period_option":order.offer.period_option,
                "risks":order.offer.risks,
                "price":order.offer.price,
                "limit":order.offer.coverage,
                "limit_currency":order.offer.limit_currency,
                "offerId":order.offer.id,
                "purposeOfTrip":order.offer.purposeOfTrip,
                "fullPrice":order.allPrice,
                "yearProgram":order.offer.yearProgram
            };

            let TouristsList = [];

            order.insurants.forEach((insurant) => {
                TouristsList.push({ name: insurant.name, surname: insurant.surname, dateBirth: insurant.dateBirthAt, identificationCode: insurant.documentation.uin,
                    withoutIdentificationCode: insurant.documentation.withoutUin,
                    passportSerial: insurant.documentation.serial, passportNumber: insurant.documentation.number, passportDate: insurant.documentation.dateOfIssue});
            })

            let userInfo = {
                "identificationCode":order.insurant.documentation.uin,
                "withoutIdentificationCode":order.insurant.documentation.withoutUin,
                "name":order.insurant.name,
                "surname":order.insurant.surname,
                "dateBirth":order.insurant.dateBirthAt,
                "phone":order.insurant.phone,
                "mail":order.insurant.email,
                "city":order.insurant.cityObject,
                "street":order.insurant.street,
                "house":order.insurant.house,
                "apartment":order.insurant.apartment,
                "documentType":order.insurant.documentation.documentType,
                "passportSerial":order.insurant.documentation.serial,
                "passportNumber":order.insurant.documentation.number,
                "passportDate":order.insurant.documentation.dateOfIssue
            };

            const searchParamsTourism = {
                date: order.offer.startDate,
                end: order.offer.endDate,
                days: order.offer.period_option,
                country: {id:order.offer.country.id,ewa_id:order.offer.country.ewa_id,name:order.offer.country.name},
                tourists: order.offer.tourists,
                humans: this.humans,
                yearProgram: order.offer.yearProgram,
                birthDates: this.birthDates,
                filters: {
                    "franchise": "",
                    "options_list": [],
                    "activities": {
                        "name": "Без активності",
                        "value": "no_activities"
                    },
                    "activitiesItem": "Без активностей"
                },
                activities: "no_activities",
            };

            let response = '';

            if (order.contract && order.contractId) {

                response = await downloadTourismContract(order.contractId);

                Vue.set(this.loadMoreOptions, order.id + order.typeId, false);

                if (response?.data?.data?.contractDraft_path) {
                    window.open(response.data.data.contractDraft_path);
                } else {
                    this.$alert('Спробуйте пізніше');
                }

                return true;
            } else {
                orderTourism(offerInfo, searchParamsTourism, userInfo, TouristsList, order.id, params).then((response) => {

                    Vue.set(this.loadMoreOptions, order.id + order.typeId, false);

                    if (response && response.data.count >= 1) {
                        const contractData = response.data.data[0];

                        if (contractData.contractDraft_path) {
                            window.open(contractData.contractDraft_path);
                        } else {
                            this.$alert('Спробуйте пізніше');
                        }
                    } else {
                        this.$alert("Чернетка не сбережена. Спробуйте ще раз", '', 'error', {width: '300px'});
                    }

                }).catch((error) => { console.error(error);
                    $('#modalPayAnimation').modal('hide');
                    Vue.set(this.loadMoreOptions, order.id + order.typeId, false);
                    this.$alert('Щось пішло не так. Чернетка не сбережена.')
                });
            }
        },
        prolong: async function(order) {
            await this.copy(order, true);
        },
        showMtsbuCodeAdditionalInfo: function(order) {
              if((order.typeId == this.orderTypes['dgo'] && !order?.contractDgo?.mtsbuCode) || order.typeId == this.orderTypes['autolawyer']){
                return 'на основi'
              }
        },
        activateTab :function (status) {
            this.$set(this.listQuery, 'statusId', status.id);
        },
        takeContract: async function (order, type) {
          if (order.typeId == 1 && order.dgo)
          {
            this.ordersResponceList = await this.downloadData(order, this.ordersList, type)
          }
          else{
            this.downloadData(order, this.ordersList, 1)
          }
        },
    },
    watch: {
        'listQuery.statusId': function (newValue, oldValue) {
            return this.$emit('init-orders-list');
        },
      'orders': {
        handler: function (val, oldVal) {
            this.ordersList = val;
        },
        deep: true
      },
      'ordersResponceList': {
        handler: function (val, oldVal) {
          this.ordersList = this.updateExtraFiles(this.ordersList, val);
        },
        deep: true
      },
    }
}
</script>
